import * as React from 'react'

import { NextPage } from '@grandvisionhq/next'
import { Hooks } from '@grandvisionhq/stores-v2'
import { compose } from '@grandvisionhq/utils'
import { Page, Props } from '@grandvisionhq/www-next/pages/stores'

import { withCms, withLayout } from '../cms'

const StoresPage: NextPage<Props | undefined> = (props) => {
  const { useDefaultStoreSearchFilters } = Hooks
  const { openNow, openOnTheWeekend, wheelChairAccessible } = useDefaultStoreSearchFilters()
  const storeSearchFilters = React.useMemo(
    () => ({ openNow, openOnTheWeekend, wheelChairAccessible }),
    [openNow, openOnTheWeekend, wheelChairAccessible]
  )

  return <Page {...props} storeSearchFilters={storeSearchFilters} />
}

StoresPage.getInitialProps = Page.getInitialProps

export default compose<Props, Props>(withCms(), withLayout())(StoresPage)
